import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import {
  removeFavoritedDealer,
  addFavoritedDealer,
  setFavoriteMessage,
} from "../../../contexts/Favorites/actions"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import Icon from "../../atoms/Icon"
import { Link } from "../../atoms/Link"
import { navigate } from "gatsby"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

const DealerHeader: React.FC = () => {
  const { dealer } = useContext(DealerDetailsPageContext)
  const { _ } = useContext(LanguageContext)

  const [{ favoritedDealer }, dispatch] = useContext(FavoritesContext)

  const { trackTealEvent } = useTealiumEvent()

  const handleFavoriteClick = () => {
    if (isFavorite) {
      dispatch(removeFavoritedDealer())
    } else {
      dispatch(addFavoritedDealer(dealer))
      dispatch(setFavoriteMessage("dealer"))
    }

    trackTealEvent({
      dealer_name: dealer?.Name,
      dealer_code: dealer?.DealerCode,
      tealium_event: "favorite_click",
      vehicle_category: isFavorite ? "Unfavorite Dealer" : "Dealer",
    })
  }

  const isFavorite =
    favoritedDealer && favoritedDealer.DealerCode === dealer.DealerCode

  return (
    <div css={[tw`w-full bg-black px-6 py-6`, tw`lg:(px-10)`, tw`2xl:(px-16)`]}>
      <div css={[tw`flex items-center`]}>
        <Icon.Chevron color="white" direction="left" css={[tw`h-3 mr-2`]} />
        <Link
          animated
          animatedThin
          onClick={() => navigate(-1)}
          css={[
            tw`text-white inline-flex items-center pl-0 text-sm normal-case py-0 hover:(cursor-pointer)`,
            tw`md:(font-book)`,
            tw`focus-visible:(outline-white  )`,
          ]}
        >
          <span css={[tw`inline-flex whitespace-nowrap`]}>
            {_("Back to Dealer List")}
          </span>
        </Link>
      </div>
      <div css={[tw``]}>
        <h1
          css={[
            tw`text-white text-3xl font-semibold inline-flex`,
            tw`md:(text-5xl)`,
          ]}
        >
          {dealer?.Name}
        </h1>
        <button
          onClick={handleFavoriteClick}
          css={[
            tw`ml-2 justify-center inline-flex items-center rounded-full h-11 w-11 p-2`,
            tw`hover:(bg-gray-700)`,
            tw`focus-visible:(outline-white bg-gray-700)`,
          ]}
          aria-label={
            isFavorite
              ? `Unfavorite ${dealer?.Name}`
              : `Favorite ${dealer?.Name}`
          }
          analytics-id="favorite:dealer info:"
        >
          <Icon.Heart
            color="gray-50"
            css={[tw`h-8 -mb-1`]}
            filled={isFavorite}
          />
        </button>
      </div>
      {/* <div
        css={[
          tw`col-start-8 col-span-6 text-right text-white mt-2`,
          tw`md:(col-start-9 col-span-3 mt-6)`,
        ]}
      >
        <span css={[tw`flex items-center justify-end text-xs`]}>
          My Dealer
          <Icon.Heart color="red-400" filled css={[tw`max-h-6 ml-2`]} />
        </span>
      </div> */}
    </div>
  )
}

export default DealerHeader
